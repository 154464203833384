<template>
    <modal ref="modalLeecheros" :titulo="$config.vendedor" tamano="modal-lg" no-cancelar no-aceptar>
        <div class="row mx-0">
            <cargando v-if="cargando" />
            <div class="col-auto" />
            <div class="col px-0">
                <div class="row mx-0">
                    <div class="col">
                        <p class="text-general f-500 mb-3 mb-3">
                            {{ $config.vendedor }}
                        </p>
                        <template v-if="leecherosVinculados.length > 0">
                            <div v-for="(leechero,x) in leecherosVinculados" :key="x" class="row mx-0 mb-3">
                                <img :src="leechero.logo != null ? leechero.logo : '/img/no-imagen/sin_cliente.svg'" class="obj-cover br-4" width="46" height="46" />
                                <div class="col">
                                    <p class="text-general f-600 f-15">
                                        {{ leechero.leechero }}
                                    </p>
                                    <p class="text-general f-14">
                                        {{ leechero.cedi }}
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row mx-0 mb-3">
                                <div class="col">
                                    <small class="text-general2">
                                        No hay {{ $config.vendedor }} asociados
                                    </small>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col">
                        <p class="text-general f-500 mb-3 mb-3">
                            {{ $config.vendedor }} de desvinculación 
                            <el-tooltip :content="`En caso de desafiliar a algún usuario, este quedará vinculado a este ${$config.vendedor} de forma inmediata`" placement="bottom" effect="light">
                                <i class="icon-help-circle-outline f-18 ml-3" />
                            </el-tooltip>
                        </p>
                        <template v-if="leecherosDesvinculados.length > 0">
                            <div v-for="(leechero,x) in leecherosDesvinculados" :key="x" class="row mx-0 mb-3">
                                <img :src="leechero.logo != null ? leechero.logo : '/img/no-imagen/sin_cliente.svg'" class="obj-cover br-4" width="46" height="46" />
                                <div class="col">
                                    <p class="text-general f-600 f-15">
                                        {{ leechero.leechero }}
                                    </p>
                                    <p class="text-general f-14">
                                        {{ leechero.cedi }}
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="row mx-0 mb-3">
                                <div class="col">
                                    <small class="text-general2">
                                        No hay {{ $config.vendedor }} asociados
                                    </small>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import Service from '~/services/nomina/nomina'
export default {
    data(){
        return {
            cargando: false,
            leecherosVinculados: [],
            leecherosDesvinculados: [],
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
    },
    methods: {
        async getLeecherosConvenio(){
            try {
                this.cargando = true;
                const {data} = await Service.getLeecherosConvenio(this.id_convenio)
                this.leecherosVinculados = data.vinculados;
                this.leecherosDesvinculados = data.desvinculados;
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        toggle(){
            this.getLeecherosConvenio();
            this.$refs.modalLeecheros.toggle();
        }
    }
}
</script>